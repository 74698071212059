// isstracking.js

import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import ISSTrackingContent from 'components/ISSTrackingContent'

function ISSTrackingPage({ location }) {
	// Page-Title Override
	// const pageTitle = location ? location.pathname.replace(/\//g, '') : ''
	const pageTitle = "Playground :: RxJs Observables Page"

	return (
		<Layout id="Top" location={location} title={pageTitle}>
			<ISSTrackingContent />
		</Layout>
	)
}

ISSTrackingPage.propTypes = {
	location: PropTypes.object,
}

export default ISSTrackingPage
