import React from "react"
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import ISSTrackingHeader from '../../StaticQueries/ISSTrackingHeader'
import ISSTrackingGraphic from '../../StaticQueries/ISSTrackingGraphic'
import Swal from 'sweetalert2'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography' // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

const typography = new Typography(irvingTheme)


const styles = theme => ({
    paper: {
        maxWidth: 1700,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing.unit,
    },
    contentWrapper: {
        margin: '40px 16px',
    },
    container: {
        padding: '48px 36px 0',
    },
    body: {
        height: '100%',
        margin: '0'
    },
    fullHeight: {
        height: '100%'
    }
})


function ISSTrackingContent({ classes }) {
    let timerInterval

    Swal.fire({
            title: 'Preparing Playground',
            html: 'Waiting <b></b> milliseconds',
            timer: 3000,
            timerProgressBar: true,
            onBeforeOpen: () => {
                Swal.showLoading()
                timerInterval = setInterval(() => {
                    // [TOLI, 20191111 - Introduced check, but having build issues...]
                    // if (Swal.getContent() != null) {
                    Swal.getContent().querySelector('b')
                        .textContent = Swal.getTimerLeft()
                        // }
                }, 100)
            },
            onClose: () => {
                clearInterval(timerInterval)
            }
        })
        // .then((result) => {
        //   if (
        //     result.dismiss === Swal.DismissReason.timer
        //   ) {
        //     console.log('I was closed by the timer') // eslint-disable-line
        //   }
        // })

    return ( <
        >
        <
        div className = { classes.container } >
        <
        Paper className = { classes.paper } >

        <
        AppBar className = { classes.searchBar }
        position = "static"
        color = "default"
        elevation = { 0 } > { /* <h1 align="center" style={{ color: 'green' }}>-= 'I°S°S ≡≡≡ T°r°a°c°k°i°n°g ≡≡≡ P°a°g°e =-</h1> */ } <
        ISSTrackingHeader / >
        <
        /AppBar>

        <
        div id = "mamma"
        className = { classes.contentWrapper }
        height = "100%" > { /* <Typography color="textSecondary" align="justify"> */ } <
        typography color = "textPrimary"
        align = "justify" >
        <
        ISSTrackingGraphic / >
        <
        br / >
        <
        h1 align = "center"
        style = {
            { color: 'green' } } > Functional Programming::Reactive Demonstration < /h1> <
        hr / >

        <
        iframe id = "inlineFrame"
        // title="Inline Frame"
        width = "100%"
        height = "1000px"
        // src="https://isstracking.now.sh/">
        // src = "https://isstrackingwithreact.now.sh" >
        src = "https://iss-tracking-with-react-without-heroku.vercel.app/" >
        <
        /iframe> <
        /typography> { /* </Typography> */ } <
        /div> <
        /Paper> <
        /div> <
        />
    )
}

ISSTrackingContent.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ISSTrackingContent)